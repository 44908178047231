import payload_plugin_ABf1ZSdiof from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.8_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.28.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_3GoRIRaVWe from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._x2kuljn26byi2cpgyglz2vzycy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_yHV9LEXQup from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._x2kuljn26byi2cpgyglz2vzycy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_SQyegOvgnA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._x2kuljn26byi2cpgyglz2vzycy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_KGhiVD9n7Z from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._x2kuljn26byi2cpgyglz2vzycy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_w6GJo9f0gA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._x2kuljn26byi2cpgyglz2vzycy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gNqeS2elhc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._x2kuljn26byi2cpgyglz2vzycy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4Szthgp69B from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._x2kuljn26byi2cpgyglz2vzycy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_s2HcYizdmB from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.8_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.28.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_piktjq7n05 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._x2kuljn26byi2cpgyglz2vzycy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_ZVq1Vq58AW from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_change-case@5.4.4_magicast@0.3.5_rollup@4.28.0_typescript@5.6.3_vite@5.4.11_@_4rknan7bvguw7n3po7zzzqwrvq/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_s65mhv53wc from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_change-case@5.4.4_magicast@0.3.5_rollup@4.28.0_typescript@5.6.3_vite@5.4.11_@_4rknan7bvguw7n3po7zzzqwrvq/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_DjHNxOlTsL from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_change-case@5.4.4_magicast@0.3.5_rollup@4.28.0_typescript@5.6.3_vite@5.4.11_@_4rknan7bvguw7n3po7zzzqwrvq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_9LXRoB01E1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.28.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_Ydz1TY4F0J from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.10.0_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_sass@1.82.0_te_uibsqt3hwouqyuiwivgmqhliom/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/opt/buildhome/repo/.nuxt/formkitPlugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/buildhome/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_AdmJgpKeoB from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.28.0_vite@5.4._mtpam2f4ebdhlgydwfts574qe4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _01_api_GIiciWaEWX from "/opt/buildhome/repo/plugins/01.api.ts";
import _02_nuxtClientInit_client_42PBK3TMYu from "/opt/buildhome/repo/plugins/02.nuxtClientInit.client.ts";
import dayjs_pGiXRjcsJO from "/opt/buildhome/repo/plugins/dayjs.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
export default [
  payload_plugin_ABf1ZSdiof,
  revive_payload_client_3GoRIRaVWe,
  unhead_yHV9LEXQup,
  router_SQyegOvgnA,
  payload_client_KGhiVD9n7Z,
  navigation_repaint_client_w6GJo9f0gA,
  check_outdated_build_client_gNqeS2elhc,
  chunk_reload_client_4Szthgp69B,
  plugin_vue3_s2HcYizdmB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_piktjq7n05,
  slideovers_ZVq1Vq58AW,
  modals_s65mhv53wc,
  colors_DjHNxOlTsL,
  plugin_client_9LXRoB01E1,
  plugin_Ydz1TY4F0J,
  formkitPlugin_pZqjah0RUG,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_AdmJgpKeoB,
  _01_api_GIiciWaEWX,
  _02_nuxtClientInit_client_42PBK3TMYu,
  dayjs_pGiXRjcsJO,
  sentry_client_shVUlIjFLk
]